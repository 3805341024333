import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useAppDispatch } from '../store/store';
import { HOUSE_COMMITTEES, SENATE_COMMITTEES, JOINT_COMMITTEES } from '../constants/committees';
import { Link } from 'react-router-dom';
import {
    createUser,
    logIn,
    redirectToPayment,
    selectEmail,
    signUp,
} from '../store/reducers/userSlice';
import { useSelector } from 'react-redux';

const PricingCommitteePage = () => {
    const dispatch = useAppDispatch();
    const userEmail = useSelector(selectEmail);
    const [selectedCommittees, setSelectedCommittees] = useState<string[]>([]);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState<string>('');
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        title: '',
        email: '',
        phone: '',
    });

    const handleCommitteeChange = (committee: string) => {
        setSelectedCommittees((prev) =>
            prev.includes(committee) ? prev.filter((c) => c !== committee) : [...prev, committee]
        );
    };

    const handlePeriodChange = (period: string) => {
        setSubscriptionPeriod(period);
    };

    const handleUserDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    const handleSignUpRedirect = async () => {
        dispatch(signUp());
    };

    const handlePayment = async () => {
        await dispatch(
            createUser({
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                company: userDetails.organization,
                job_title: userDetails.title,
                industry: '',
                phone: userDetails.phone,
                email: userDetails.email,
            })
        ).unwrap();

        await dispatch(redirectToPayment('prod_Qh2J0IZzXwaE7u')).unwrap();
    };

    const calculateCost = (): string => {
        var cost = 0;
        const numCommittees = selectedCommittees.length;

        if (subscriptionPeriod === '6 months') {
            // calculate variable fee
            if (numCommittees >= 20) {
                cost += 500 * numCommittees;
            } else if (numCommittees >= 11) {
                cost += 550 * numCommittees;
            } else if (numCommittees >= 6) {
                cost += 600 * numCommittees;
            } else if (numCommittees >= 2) {
                cost += 700 * numCommittees;
            } else {
                cost += 1000 * numCommittees;
            }
        } else if (subscriptionPeriod === '12 months') {
            // calculate variable fee
            if (numCommittees >= 20) {
                cost += 700 * numCommittees;
            } else if (numCommittees >= 11) {
                cost += 775 * numCommittees;
            } else if (numCommittees >= 6) {
                cost += 850 * numCommittees;
            } else if (numCommittees >= 2) {
                cost += 1000 * numCommittees;
            } else {
                cost += 1500 * numCommittees;
            }
        }
        return `Total: $${cost.toLocaleString()} - ${numCommittees} committee(s) for ${subscriptionPeriod}`;
    };

    const isDisabled = !userEmail;

    return (
        <Box className="mt-32 mb-24 mx-8 xl:mx-24">
            <Typography variant="h4" gutterBottom>
                Committee Monitoring Subscription
            </Typography>
            {!userEmail && (
                <>
                    <Typography sx={{ marginTop: '2rem' }} variant="h6" gutterBottom>
                        First, please{' '}
                        <span
                            className="text-sky-600 hover:underline pointer"
                            onClick={handleLoginRedirect}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            Sign In
                        </span>{' '}
                        to your LobbyIQ account or{' '}
                        <span
                            className="text-sky-600 hover:underline pointer"
                            onClick={handleSignUpRedirect}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            Sign Up
                        </span>{' '}
                        to create one.
                    </Typography>
                </>
            )}

            <div style={{ opacity: isDisabled ? 0.3 : 1 }}>
                <Typography sx={{ marginTop: '2rem' }} variant="h6" gutterBottom>
                    Step 1: Select Committees
                </Typography>
                <Typography sx={{ marginBottom: '0.5rem' }}>House Committees</Typography>
                <Grid container spacing={2}>
                    {HOUSE_COMMITTEES.map((committee, index) => (
                        <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedCommittees.includes(committee.name)}
                                        onChange={() => handleCommitteeChange(committee.name)}
                                        disabled={isDisabled}
                                    />
                                }
                                label={
                                    <Link
                                        to={committee.link}
                                        className="text-sky-600 hover:underline"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(committee.link, '_blank')?.focus();
                                        }}
                                    >
                                        {committee.name}
                                    </Link>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                    Senate Committees
                </Typography>
                <Grid container spacing={2}>
                    {SENATE_COMMITTEES.map((committee, index) => (
                        <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedCommittees.includes(committee.name)}
                                        onChange={() => handleCommitteeChange(committee.name)}
                                        disabled={isDisabled}
                                    />
                                }
                                label={
                                    <Link
                                        to={committee.link}
                                        className="text-sky-600 hover:underline"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(committee.link, '_blank')?.focus();
                                        }}
                                    >
                                        {committee.name}
                                    </Link>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                    Joint Committees
                </Typography>
                <Grid container spacing={2}>
                    {JOINT_COMMITTEES.map((committee, index) => (
                        <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedCommittees.includes(committee.name)}
                                        onChange={() => handleCommitteeChange(committee.name)}
                                        disabled={isDisabled}
                                    />
                                }
                                label={
                                    <Link
                                        to={committee.link}
                                        className="text-sky-600 hover:underline"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(committee.link, '_blank')?.focus();
                                        }}
                                    >
                                        {committee.name}
                                    </Link>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 2: Select Time Period of Subscription
                </Typography>
                <FormControl fullWidth>
                    <InputLabel id="subscription-period-label">Time Period</InputLabel>
                    <Select
                        labelId="subscription-period-label"
                        label="Time Period"
                        value={subscriptionPeriod}
                        fullWidth
                        onChange={(event) => handlePeriodChange(event.target.value)}
                        disabled={isDisabled}
                    >
                        <MenuItem value="6 months">Six months starting from today</MenuItem>
                        <MenuItem value="12 months">Twelve months starting from today</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 3: Subscription Cost
                </Typography>
                <Typography>
                    {selectedCommittees.length !== 0 && subscriptionPeriod !== ''
                        ? calculateCost()
                        : 'Please complete Steps 1 and 2'}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 4: Enter Your Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={userDetails.firstName}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={userDetails.lastName}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Organization"
                            name="organization"
                            value={userDetails.organization}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Title"
                            name="title"
                            value={userDetails.title}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Email"
                            name="email"
                            value={userDetails.email}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={userDetails.phone}
                            onChange={handleUserDetailsChange}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 5: Payment
                </Typography>
                <button
                    className="text-center bg-sky-700 text-white transition ease-in-out hover:bg-sky-600 rounded-md p-3"
                    onClick={() => handlePayment()}
                    disabled={isDisabled}
                >
                    Proceed to Payment
                </button>
            </div>
        </Box>
    );
};

export default PricingCommitteePage;
