import React, { useCallback, useEffect, useState } from 'react';
import Dashboard from '../../../pages/dashboards/Dashboard';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';

import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { committeeMember } from '../../committee-dashboards/interfaces/committee-entity.interface';
import {
    fetchCommitteesSelections,
    selectChosenCommittees,
    selectCommitteesLoading,
} from '../../../store/reducers/customSelectionSlice';
import { DashboardNotLicenced } from '../../../pages/misc/DashboardNotLicenced';
import { ProductType, selectUserPermissions } from '../../../store/reducers/userSlice';
import { CommitteeMembersLobbied } from '../../../tooltips/committees/ComitteeMembersLobbies';
import { CommitteeSectors } from '../../../tooltips/committees/ComitteeSectors';
import { CommitteeOrganizationsLobbying } from '../../../tooltips/committees/ComitteeOrganizationsLobbying';
import { CommitteeSubjects } from '../../../tooltips/committees/ComitteeSubjects';
import { CommitteeLobbyFirms } from '../../../tooltips/committees/ComitteeLobbyFirms';
import {
    fetchOrganizationCluster,
    selectOrganizationCluster,
    selectOrganizationClusterLoading,
    fetchSectorCluster,
    selectSectorCluster,
    selectSectorClusterLoading,
    fetchDPOHCluster,
    selectDPOHCluster,
    selectDPOHClusterLoading,
    fetchFirmCluster,
    selectFirmCluster,
    selectFirmClusterLoading,
    fetchSubjectCluster,
    selectSubjectCluster,
    selectSubjectClusterLoading,
    fetchCommitteeRecentLobbying,
    selectCommitteeRecentLobbying,
    selectCommitteeRecentLobbyingLoading,
    fetchCommitteeMembers,
    selectCommitteeMembers,
    selectCommitteeMembersLoading,
    fetchCommitteeOverview,
    selectCommitteeOverview,
    selectCommitteeOverviewLoading,
} from '../store/senateCommitteeSlice';

const SenateCommitteeDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const url_params = useParams();
    const [searchParams] = useSearchParams();

    const entityCode =
        url_params.senateCmteId !== undefined ? url_params.senateCmteId.toLowerCase() : '';

    const entity_name = searchParams.get('name');

    const overview: entityOverviews | null = useSelector(selectCommitteeOverview);
    const overview_loading: boolean = useSelector(selectCommitteeOverviewLoading);

    const members: committeeMember[] | null = useSelector(selectCommitteeMembers);
    const members_loading: boolean = useSelector(selectCommitteeMembersLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectCommitteeRecentLobbying);
    const activity_loading: boolean = useSelector(selectCommitteeRecentLobbyingLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const sector_cluster: entityCluster | null = useSelector(selectSectorCluster);
    const sector_cluster_loading: boolean = useSelector(selectSectorClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const subject_cluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subject_cluster_loading: boolean = useSelector(selectSubjectClusterLoading);

    // const export_loading: boolean = useSelector(selectCommitteeExportLoading);
    // const export_fail: boolean = useSelector(selectCommitteeExportFail);

    const committeeSelections = useSelector(selectChosenCommittees);
    const committeeSelectionsLoading = useSelector(selectCommitteesLoading);
    const userPermissions = useSelector(selectUserPermissions);

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    const canAccessSelection = useCallback(() => {
        if (
            userPermissions?.productType === ProductType.ENTERPRISE ||
            userPermissions?.productType === ProductType.TRIAL
        ) {
            return true;
        } else return !!committeeSelections.find((selection) => selection.code === entityCode);
    }, [userPermissions, committeeSelections, entityCode]);

    useEffect(() => {
        if (!committeeSelectionsLoading && canAccessSelection()) {
            dispatch(fetchCommitteeOverview({ senateCmteId: entityCode, date: date }));
            dispatch(fetchCommitteeMembers({ senateCmteId: entityCode, date: date }));
            dispatch(
                fetchCommitteeRecentLobbying({
                    senateCmteId: entityCode,
                    date: date,
                })
            );
            dispatch(fetchOrganizationCluster({ senateCmteId: entityCode, date: date }));
            dispatch(fetchSectorCluster({ senateCmteId: entityCode, date: date }));
            dispatch(fetchFirmCluster({ senateCmteId: entityCode, date: date }));
            dispatch(fetchDPOHCluster({ senateCmteId: entityCode, date: date }));
            dispatch(fetchSubjectCluster({ senateCmteId: entityCode, date: date }));
        }
    }, [dispatch, entityCode, date, canAccessSelection, committeeSelectionsLoading]);

    useEffect(() => {
        if (
            !(
                userPermissions?.productType === ProductType.ENTERPRISE ||
                userPermissions?.productType === ProductType.TRIAL
            ) &&
            committeeSelections.length === 0
        ) {
            dispatch(fetchCommitteesSelections());
        }
    }, [committeeSelections.length, dispatch, userPermissions?.productType]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return canAccessSelection() ? (
        <Dashboard
            type="senate committees"
            entity_code={entityCode}
            date={date}
            handleDateChange={setDate}
            includeOverview
            overview={overview}
            overviewHeaders={overview_headers}
            overviewIndent={['by Consultants']}
            overviewLoading={overview_loading}
            includeMemberships
            memberships={members}
            membershipsLoading={members_loading}
            includeRecentLobbying
            recentLobbying={activity}
            recentLobbyingLoading={activity_loading}
            // Data not in DB yet
            // includeTopKeywords
            // bumperData={terms}
            // bumperDataLoading={terms_loading}
            // exportFail={export_fail}
            // exportLoading={export_loading}
            clusters={[
                {
                    cluster_data: org_cluster,
                    title: 'Organizations Lobbying Committee Members',
                    tooltipContent: CommitteeOrganizationsLobbying,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: sector_cluster,
                    title: 'Sectors Lobbying Committee Members',
                    tooltipContent: CommitteeSectors,
                    loading: sector_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'Committee Members Lobbied',
                    tooltipContent: CommitteeMembersLobbied,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: subject_cluster,
                    title: 'Lobbying Subjects',
                    tooltipContent: CommitteeSubjects,
                    loading: subject_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: CommitteeLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
            hideExport
        />
    ) : (
        <DashboardNotLicenced type="committees" name={entity_name} />
    );
};

export default SenateCommitteeDataLayer;
