import axios from 'axios';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverview,
    entityOverviewRefined,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import moment from 'moment';
import {
    consultantLinkFormatter,
    DPOHLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../../../helpers/internal_routing';
import { committeeMember } from '../../committee-dashboards/interfaces/committee-entity.interface';

// Base URL for accessing the API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'Organizations',
        'Sectors',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of the given senate committee
const fetchSenateCommitteeOverview = async (senateCmteId: string, date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/senate/committee/${senateCmteId}/overview?monthly=false`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.committeeOverview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                Organizations: entry.organizations,
                Sectors: entry.sectors,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(
        `${baseURL}/senate/committee/${senateCmteId}/overview?monthly=true`
    );

    const monthly_data: entityOverview[] = monthly_response.data.committeeOverview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch a list of members of the given senate committee
const fetchSenateCommitteeMembers = async (senateCmteId: string, date: string) => {
    const members_response = await axios.get(
        `${baseURL}/senate/committee/${senateCmteId}/senatorList`
    );
    const member_data: committeeMember[] = members_response.data.senateCommitteeMembers;
    const converted_member_data = member_data.map((entry: committeeMember) => {
        return {
            ...entry,
            name: `URL#${entry.name}#/senate/members/${entry.code}?name=${entry.name}`,
        };
    });

    return converted_member_data;
};

// Fetch recent lobbying communications to a given senate committee
const fetchSenateCommitteeRecentLobbyingActivity = async (senateCmteId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/committee/${senateCmteId}/activity?limit=25&date=${date}`
    );
    const data: entityLobbyingActivity[] = response.data.committeeActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};
// Fetch recent lobbying communications to a given senate committee
const fetchSenateCommitteeCluster = async (
    senateCmteId: string,
    type: 'organizations' | 'sectors' | 'lobbyfirms' | 'dpoh' | 'subject',
    date: string
) => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const cluster_reponse = await axios.get(
        `${baseURL}/senate/committee/${senateCmteId}/cluster/${type}?date=${date}`
    );
    const cluster_data: entityCluster = cluster_reponse.data;

    return cluster_data;
};

export const SenateCommitteeAPI = {
    fetchSenateCommitteeOverview,
    fetchSenateCommitteeMembers,
    fetchSenateCommitteeRecentLobbyingActivity,
    fetchSenateCommitteeCluster,
};
