export const SENATE_COMMITTEES = [
    { name: 'AEFA', link: 'https://sencanada.ca/en/committees/aefa/44-1' },
    { name: 'AGFO', link: 'https://sencanada.ca/en/committees/agfo/44-1' },
    { name: 'AOVS', link: 'https://sencanada.ca/en/committees/aovs/44-1' },
    { name: 'APPA', link: 'https://sencanada.ca/en/committees/appa/44-1' },
    { name: 'BANC', link: 'https://sencanada.ca/en/committees/banc/44-1' },
    { name: 'CIBA', link: 'https://sencanada.ca/en/committees/ciba/44-1' },
    { name: 'CONF', link: 'https://sencanada.ca/en/committees/conf/44-1' },
    { name: 'ENEV', link: 'https://sencanada.ca/en/committees/enev/44-1' },
    { name: 'LCJC', link: 'https://sencanada.ca/en/committees/lcjc/44-1' },
    { name: 'NFFN', link: 'https://sencanada.ca/en/committees/nffn/44-1' },
    { name: 'OLLO', link: 'https://sencanada.ca/en/committees/ollo/44-1' },
    { name: 'POFO', link: 'https://sencanada.ca/en/committees/pofo/44-1' },
    { name: 'RIDR', link: 'https://sencanada.ca/en/committees/ridr/44-1' },
    { name: 'RPRD', link: 'https://sencanada.ca/en/committees/rprd/44-1' },
    { name: 'SECD', link: 'https://sencanada.ca/en/committees/secd/44-1' },
    { name: 'SELE', link: 'https://sencanada.ca/en/committees/sele/44-1' },
    { name: 'SOCI', link: 'https://sencanada.ca/en/committees/soci/44-1' },
    { name: 'TRCM', link: 'https://sencanada.ca/en/committees/trcm/44-1' },
];

export const HOUSE_COMMITTEES = [
    { name: 'ACVA', link: 'https://www.ourcommons.ca/Committees/en/ACVA' },
    { name: 'AFGH', link: 'https://www.ourcommons.ca/Committees/en/AFGH' },
    { name: 'AGRI', link: 'https://www.ourcommons.ca/Committees/en/AGRI' },
    { name: 'CACN', link: 'https://www.ourcommons.ca/Committees/en/CACN' },
    { name: 'CHPC', link: 'https://www.ourcommons.ca/Committees/en/CHPC' },
    { name: 'CIIT', link: 'https://www.ourcommons.ca/Committees/en/CIIT' },
    { name: 'CIMM', link: 'https://www.ourcommons.ca/Committees/en/CIMM' },
    { name: 'ENVI', link: 'https://www.ourcommons.ca/Committees/en/ENVI' },
    { name: 'ETHI', link: 'https://www.ourcommons.ca/Committees/en/ETHI' },
    { name: 'FAAE', link: 'https://www.ourcommons.ca/Committees/en/FAAE' },
    { name: 'FEWO', link: 'https://www.ourcommons.ca/Committees/en/FEWO' },
    { name: 'FINA', link: 'https://www.ourcommons.ca/Committees/en/FINA' },
    { name: 'FOPO', link: 'https://www.ourcommons.ca/Committees/en/FOPO' },
    { name: 'HESA', link: 'https://www.ourcommons.ca/Committees/en/HESA' },
    { name: 'HUMA', link: 'https://www.ourcommons.ca/Committees/en/HUMA' },
    { name: 'INAN', link: 'https://www.ourcommons.ca/Committees/en/INAN' },
    { name: 'INDU', link: 'https://www.ourcommons.ca/Committees/en/INDU' },
    { name: 'JUST', link: 'https://www.ourcommons.ca/Committees/en/JUST' },
    { name: 'LANG', link: 'https://www.ourcommons.ca/Committees/en/LANG' },
    { name: 'LIAI', link: 'https://www.ourcommons.ca/Committees/en/LIAI' },
    { name: 'NDDN', link: 'https://www.ourcommons.ca/Committees/en/NDDN' },
    { name: 'OGGO', link: 'https://www.ourcommons.ca/Committees/en/OGGO' },
    { name: 'PACP', link: 'https://www.ourcommons.ca/Committees/en/PACP' },
    { name: 'PROC', link: 'https://www.ourcommons.ca/Committees/en/PROC' },
    { name: 'RNNR', link: 'https://www.ourcommons.ca/Committees/en/RNNR' },
    { name: 'SECU', link: 'https://www.ourcommons.ca/Committees/en/SECU' },
    { name: 'SRSR', link: 'https://www.ourcommons.ca/Committees/en/SRSR' },
    { name: 'TRAN', link: 'https://www.ourcommons.ca/Committees/en/TRAN' },
];

export const JOINT_COMMITTEES = [
    { name: 'AMAD', link: 'https://sencanada.ca/en/committees/amad/44-1' },
    { name: 'BILI', link: 'https://sencanada.ca/en/committees/bili/44-1' },
    { name: 'DEDC', link: 'https://sencanada.ca/en/committees/dedc/44-1' },
    { name: 'REGS', link: 'https://sencanada.ca/en/committees/regs/44-1' },
];
